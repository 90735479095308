import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { humanize, pluralize } from 'utils/stringUtils';
import { groupBy } from 'utils/arrayUtils';
import { SelectDropdownButton } from 'components/Buttons';
import { StyledNumberInput } from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { CentererVertical, Flexer, FlexerColumn, FlexerRow, Spacer } from 'components/Core';
import { ArrowNarrowLeftIcon, PlusSignIcon, TrashIcon } from 'components/Icons';
import { CircleLoader } from 'components/Loaders';
import {
  INVOICING_FREQUENCIES,
  PAYMENT_OPTIONS,
  INVOICE_DATE_RELATIVE_OPTIONS,
  INVOICE_DATE_REFERENCE_OPTIONS,
  GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL,
} from 'views/Billing/consts';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getIntegrationDisplayName } from 'models/integration';
import { BillingEntitySelector } from 'views/Billing/Common/BillingEntitySelector';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { useGetAvailableTags } from 'api/billing';
import { useInvoiceMemoTemplatesAPI } from 'api/invoiceMemoTemplates/hooks';
import { ContentEditableInput } from 'components/Controls/FormikCustomInput/ContentEditableInput';
import { convertToPlainText, templateToHTMLString } from 'utils/htmlUtils';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { Line } from 'views/Wizard/styles';
import CustomCheckbox from 'components/Controls/CustomCheckbox';

import {
  BodyWrapper,
  EmptyWrapper,
  SettingsRow,
  SettingItemWithText,
  SettingItem,
  SettingItemText,
  InvoicingScheduleLine,
  InvoicingScheduleBadge,
  CustomerName,
  InvoicingScheduleSummary,
  LeftPart,
  LeftTitle,
  TransactionsAmount,
  TransactionsContainer,
  RightPart,
  RightTitle,
  GroupByWrapper,
  GroupByItem,
  GroupByItemLine,
} from './BulkCreateSettings.styles';
import { buildNewInvoicingSchedules, getSuggestedFrequency } from './utils';
import { RelatedTransactionsAction } from './RelatedTransactionsAction';

export const BulkCreateSettings = ({
  transactionsSelected,
  setTransactionsSelected,
  transactionsWithProducts,
  canBulkCreate,
  setDefaultMemoTemplateId,
  setMemo,
  memo,
  setPaymentOptions,
  paymentOptions,
  setDaysToPay,
  daysToPay,
  setAutoSend,
  autoSend,
  setFrequency,
  frequency,
  setInvoiceDateRelative,
  invoiceDateRelative,
  setInvoiceDateReference,
  invoiceDateReference,
  setGroupTransactionsBy,
  groupTransactionsBy,
  onlyFutureInvoices,
  allowPastInvoices,
  entityId,
  setEntityId,
  selectedIntegrationId,
  setSelectedIntegrationId,
  allTransactionsAreLinearOrImmediate,
  daysFromDate,
  setDaysFromDate,
  hasInvoicesThatStartInThePast,
  PastAndFutureInvoiceOptions,
  invoicingSchedules,
  setInvoicingSchedules,
  useParentCustomer,
  setUseParentCustomer,
  transactionsById,
  handleAddAdditionalTransactions,
}) => {
  const {
    orgId,
    integrations,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const glIntegrations = integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.GL);

  const [isSchedulesLoading, setIsSchedulesLoading] = useState(false);

  const [showDaysToPayInput, setShowDaysToPayInput] = useState(false);

  const {
    transactionMetadataTags = [],
    customerMetadataTags = [],
    transactionCustomerMetadataTags = [],
  } = useGetAvailableTags({ orgId });

  const handleChangeMemo = (content) => {
    setMemo(convertToPlainText(content));
  };

  const { data: invoiceMemoTemplates = [] } = useInvoiceMemoTemplatesAPI({ orgId });

  useEffect(() => {
    for (const template of invoiceMemoTemplates) {
      if (template.name === 'sample memo') {
        setMemo(template.content);
        setDefaultMemoTemplateId(template.id);
        break;
      }
    }
  }, [invoiceMemoTemplates, setMemo, setDefaultMemoTemplateId]);

  const remainingPaymentOptions = useMemo(
    () => Object.values(PAYMENT_OPTIONS).filter((po) => !paymentOptions?.includes(po)),
    [paymentOptions],
  );

  const addPaymentOption = useCallback(
    (index) => setPaymentOptions([...paymentOptions, remainingPaymentOptions[index]]),
    [remainingPaymentOptions, paymentOptions, setPaymentOptions],
  );

  const removePaymentOption = useCallback(
    (paymentOption) => setPaymentOptions(paymentOptions.filter((po) => po !== paymentOption)),
    [setPaymentOptions, paymentOptions],
  );

  const frequencyOptions = useMemo(
    () =>
      Object.values(INVOICING_FREQUENCIES).reduce((acc, curr) => {
        acc[curr] = humanize(curr);
        return acc;
      }, {}),
    [],
  );

  const invoiceDateRelativeOptions = groupBy(Object.values(INVOICE_DATE_RELATIVE_OPTIONS));

  const invoiceDateReferenceOptions = groupBy(Object.values(INVOICE_DATE_REFERENCE_OPTIONS));

  const integrationOptions = useMemo(
    () =>
      (glIntegrations ?? []).reduce((acc, integration) => {
        acc[integration.id] = getIntegrationDisplayName(integration);
        return acc;
      }, {}),
    [glIntegrations],
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsSchedulesLoading(true);
      const result = await buildNewInvoicingSchedules({
        transactionIds: transactionsSelected,
        transactionsWithProducts,
        frequency,
        orgId,
        groupTransactionsBy,
        onlyFutureInvoices,
        allowPastInvoices,
      });
      setInvoicingSchedules(result);
      setIsSchedulesLoading(false);
    };

    fetchData();
  }, [
    groupTransactionsBy,
    transactionsWithProducts,
    transactionsSelected,
    frequency,
    orgId,
    onlyFutureInvoices,
    setInvoicingSchedules,
    allowPastInvoices,
  ]);

  useEffect(() => {
    const suggestedFrequency = getSuggestedFrequency({
      transactionIds: transactionsSelected,
      transactionsWithProducts,
    });
    if (suggestedFrequency) {
      setFrequency(suggestedFrequency);
    }
  }, [transactionsSelected, transactionsWithProducts, setFrequency]);

  return (
    <>
      {isSchedulesLoading ? (
        <CircleLoader isAbsolute thickness="4px" />
      ) : (
        <>
          <BodyWrapper data-cy="bulk-create-settings">
            {isEmpty(transactionsSelected) ? (
              <EmptyWrapper>
                <ArrowNarrowLeftIcon />
                First, select some transactions to create invoicing schedules and invoices
              </EmptyWrapper>
            ) : (
              <>
                <LeftPart>
                  <LeftTitle>
                    We’ll create <span>{pluralize(invoicingSchedules.length, 'invoicing schedule')} </span>
                  </LeftTitle>

                  <TransactionsContainer>
                    {invoicingSchedules.map((invoicingSchedule, index) => (
                      <InvoicingScheduleLine key={index}>
                        <FlexerRow>
                          <InvoicingScheduleBadge>{index + 1}</InvoicingScheduleBadge>
                          <FlexerColumn>
                            <CustomerName>{invoicingSchedule.customerName}</CustomerName>
                            <InvoicingScheduleSummary>
                              {invoicingSchedule.description}: {invoicingSchedule.hasEventBased ? 'up to ' : ''}
                              {pluralize(invoicingSchedule.invoicesCount, 'invoice')}
                            </InvoicingScheduleSummary>
                          </FlexerColumn>
                        </FlexerRow>

                        <TransactionsAmount>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue: invoicingSchedule?.transactionsTotal ?? 0,
                            currency: invoicingSchedule.currency ?? currency,
                          })}
                        </TransactionsAmount>
                      </InvoicingScheduleLine>
                    ))}
                  </TransactionsContainer>
                </LeftPart>

                <RightPart>
                  <RightTitle>Invoices settings</RightTitle>

                  <SettingsRow>
                    <SettingItemWithText direction="column" gap="4px" alignItems="flex-start" width="100%">
                      <b>Group transactions by:</b>
                      <GroupByWrapper data-cy="bulk-create-settings__invoicing_schedule_group_transactions_by-selector">
                        {Object.entries(GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL).map(([value, label], index) => {
                          const isLast =
                            index === Object.entries(GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL).length - 1;
                          const isNextValueSelected =
                            groupTransactionsBy ===
                            Object.keys(GROUP_TRANSACTIONS_FOR_SCHEDULES_KEY_TO_LABEL)[index + 1];
                          return (
                            <Flexer
                              width="100%"
                              key={value}
                              gap="4px"
                              data-cy={`bulk-create-settings__invoicing_schedule_group_transactions_by-selector--${value}`}
                            >
                              <GroupByItem
                                selected={groupTransactionsBy === value}
                                onClick={() => setGroupTransactionsBy(value)}
                              >
                                {label}
                              </GroupByItem>
                              {groupTransactionsBy !== value && !isLast && !isNextValueSelected && <GroupByItemLine />}
                            </Flexer>
                          );
                        })}
                      </GroupByWrapper>
                    </SettingItemWithText>

                    <RelatedTransactionsAction
                      transactionsSelected={transactionsSelected}
                      transactionsById={transactionsById}
                      setTransactionsSelected={setTransactionsSelected}
                      handleAddAdditionalTransactions={handleAddAdditionalTransactions}
                    />

                    <SettingItemWithText data-cy="bulk-create-settings__selected-billing-entity">
                      <b>Sender organization:</b>
                      <BillingEntitySelector
                        name="selected-billing-entity"
                        value={entityId}
                        setValue={setEntityId}
                        isButton
                        fontWeight={700}
                        fontSize="12px"
                        padding="4px 8px"
                        data-cy="bulk-create-settings__selected-billing-entity-selector"
                      />
                    </SettingItemWithText>

                    {!!glIntegrations?.length && (
                      <SettingItemWithText data-cy="bulk-create-settings__selected-integration">
                        <b>General Ledger:</b>
                        <SelectDropdownButton
                          name="selected-integration"
                          selected={selectedIntegrationId}
                          options={integrationOptions}
                          onSelect={setSelectedIntegrationId}
                          fontWeight={700}
                          fontSize="12px"
                          padding="4px 8px"
                          data-cy="bulk-create-settings__selected-integration-selector"
                        />
                      </SettingItemWithText>
                    )}

                    <Line />

                    <CustomCheckbox
                      checked={useParentCustomer}
                      onChange={() => setUseParentCustomer(!useParentCustomer)}
                      label="Bill to parent customers (when possible)"
                      data-cy="bulk-create-settings__bill-to-parent__checkbox"
                    />
                  </SettingsRow>

                  <SettingsRow data-cy="bulk-create-settings__payment-options">
                    <b style={{ marginBottom: 4 }}>Payment options</b>
                    <FlexerRow>
                      {paymentOptions.map((option, index) => (
                        <SettingItem
                          key={option}
                          withMarginRight={index < paymentOptions.length - 1}
                          onClick={() => removePaymentOption(option)}
                          data-cy={`bulk-create-settings__payment-option--${option}`}
                        >
                          {humanize(option)}
                          <TrashIcon size="16px" fill="var(--primaryBlack30)" />
                        </SettingItem>
                      ))}
                      {remainingPaymentOptions.length > 0 && (
                        <SelectDropdownButton
                          name="payment-option"
                          showSelectedDirectly
                          selected={
                            <SettingItemText>
                              <PlusSignIcon size="16px" fill="var(--primaryBlack30)" />
                              Payment opt.
                            </SettingItemText>
                          }
                          options={remainingPaymentOptions}
                          onSelect={addPaymentOption}
                          data-cy="bulk-create-settings__add-payment-option"
                        />
                      )}
                    </FlexerRow>
                  </SettingsRow>

                  <SettingsRow>
                    <b style={{ marginBottom: 4 }}>General</b>

                    <FlexerRow>
                      <SettingItemWithText>
                        {showDaysToPayInput ? (
                          <StyledNumberInput
                            blueVer
                            value={daysToPay}
                            onChange={setDaysToPay}
                            inputWidth={80}
                            min={0}
                            fontSize="12px"
                            data-cy="bulk-create-settings__days-to-pay-input"
                          />
                        ) : (
                          <SettingItem
                            data-cy="bulk-create-settings__edit-days-to-pay"
                            onClick={() => setShowDaysToPayInput(true)}
                          >
                            {daysToPay}
                          </SettingItem>
                        )}
                        <Spacer width="8px" />
                        <span>Terms (Net),</span>
                      </SettingItemWithText>

                      <SettingItemWithText>
                        <SettingItem
                          data-cy="bulk-create-settings__auto-send-toggle"
                          onClick={() => setAutoSend(!autoSend)}
                        >
                          {autoSend ? 'Enabled' : 'Disabled'}
                        </SettingItem>
                        <Spacer width="8px" />
                        <span>Auto-Send</span>
                      </SettingItemWithText>
                    </FlexerRow>

                    <FlexerRow>
                      <SettingItemWithText data-cy="bulk-create-settings__frequency">
                        <SelectDropdownButton
                          name="frequency"
                          selected={frequency}
                          options={frequencyOptions}
                          onSelect={setFrequency}
                          fontWeight={700}
                          fontSize="12px"
                          noMargin
                          padding="4px 8px"
                          data-cy="bulk-create-settings__frequency-selector"
                        />
                        <span style={{ marginLeft: 4 }}>billing frequency,</span>
                      </SettingItemWithText>
                    </FlexerRow>

                    {hasInvoicesThatStartInThePast && <FlexerRow>{PastAndFutureInvoiceOptions}</FlexerRow>}
                  </SettingsRow>
                  {/* If every transaction selected is linear and immediate */}
                  {allTransactionsAreLinearOrImmediate && (
                    <SettingsRow>
                      <SettingItemWithText>
                        <b>Set invoice dates</b>
                        {[
                          INVOICE_DATE_RELATIVE_OPTIONS.N_DAYS_AFTER,
                          INVOICE_DATE_RELATIVE_OPTIONS.N_DAYS_BEFORE,
                        ].includes(invoiceDateRelative) && (
                          <>
                            <Spacer width="8px" />
                            <StyledNumberInput
                              blueVer
                              value={daysFromDate}
                              onChange={setDaysFromDate}
                              inputWidth={30}
                              min={0}
                              fontSize="12px"
                              data-cy="bulk-create-settings__days-from-date-input"
                              placeholder="N days"
                            />
                          </>
                        )}
                        <SelectDropdownButton
                          name="invoice_date_relative"
                          selected={invoiceDateRelative}
                          options={invoiceDateRelativeOptions}
                          onSelect={setInvoiceDateRelative}
                          fontWeight={700}
                          fontSize="12px"
                          padding="4px 8px"
                          data-cy="bulk-create-settings__invoice_date_relative-selector"
                        />
                        <SelectDropdownButton
                          name="invoice_date_reference"
                          selected={invoiceDateReference}
                          options={invoiceDateReferenceOptions}
                          onSelect={setInvoiceDateReference}
                          fontWeight={700}
                          fontSize="12px"
                          padding="4px 8px"
                          data-cy="bulk-create-settings__invoice_date_reference-selector"
                        />
                      </SettingItemWithText>
                    </SettingsRow>
                  )}

                  {canBulkCreate && (
                    <>
                      <FlexerColumn marginBottom="16px">
                        <CentererVertical gap="8px" marginBottom="8px">
                          <b style={{ fontSize: 12 }}>Memo</b>
                          <BillingDynamicFieldsTooltip
                            tags={{
                              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                              [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_CUSTOMER_METADATA_TAGS]: transactionCustomerMetadataTags,
                            }}
                            XOffset={300}
                          />
                        </CentererVertical>
                        <ContentEditableInput
                          minHeight="200px"
                          type="HTMLEdit"
                          HTMLString={templateToHTMLString({
                            text: memo,
                            availableTags: [
                              ...DEFAULT_AVAILABLE_TAGS,
                              ...transactionMetadataTags,
                              ...customerMetadataTags,
                              ...transactionCustomerMetadataTags,
                            ],
                          })}
                          availableTags={[
                            ...DEFAULT_AVAILABLE_TAGS,
                            ...transactionMetadataTags,
                            ...customerMetadataTags,
                            ...transactionCustomerMetadataTags,
                          ]}
                          onChange={(value) => handleChangeMemo(value)}
                        />
                      </FlexerColumn>
                    </>
                  )}
                </RightPart>
              </>
            )}
          </BodyWrapper>
        </>
      )}
    </>
  );
};
